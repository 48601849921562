<template>
  <div>
    <Menu />
    <div id="curricuLum">
      <div class="tabFixed">
        <div class="content d-flex d-flex-middle d-flex-between">
          <div class="tabs d-flex d-flex-middle">
            <p class="active">课程介绍</p>
            <p>讲师介绍</p>
            <p>课程目录</p>
            <p>常见问题Q&A</p>
          </div>
          <div class="tabBtns d-flex d-flex-middle">
            <div class="d-flex d-flex-bottom">
              <p class="fontSize16">限时优惠价</p>
              <p class="price">¥6580</p>
            </div>
            <p class="join">立即报名</p>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="curriculum">
        <div class="curriculumTop diyImg">
          <p> {{corDetailsCourse.course_Title}}</p>
        </div>
        <!-- 报名信息 -->
        <div class="curriculumInfo d-flex d-flex-middle d-flex-between">
          <div class="curriculum_info_L">
            <div class="curriculum_info_L_top d-flex d-flex-middle">
              <p class="price">¥{{corDetailsCourse.course_Price}}</p>
              <p class="color3 fontSize12">讲师：{{corDetailsCourse.teacher_RealName}}（共13课时）</p>
              <p class="color3 fontSize12"><span>{{corDetailsCourse.course_ReportCount}}</span>人已报名</p>
            </div>
            <div class="curriculum_info_L_bottom d-flex d-flex-middle">
              <p>八大规范真题</p>
              <p>考前提分集训</p>
              <p>应试技巧</p>
              <p>仿真模考</p>
              <p>考点精读</p>
            </div>
          </div>
          <!-- <div class="curriculum_info_R d-flex d-flex-middle">
            <p class="join">立即报名</p>
            <div class="collectionBox d-flex d-flex-middle d-flex-center">
              <img src="../assets/images/collection.png" />
              <p class="color fontSize12">收藏</p>
            </div>
            <div class="collectionBox hide d-flex d-flex-middle d-flex-center">
              <img src="../assets/images/collection_i.png" />
              <p class="color9 fontSize12">取消</p>
            </div>
          </div> -->
        </div>
        <!-- crumbs -->
        <div class="crumbs">
          <div class="content d-flex d-flex-middle">
            <p class="color9 fontSize12">当前所在位置：</p>
            <a href="javascript:;" class="color9 fontSize12">首页</a>
            <p class="color9 fontSize12 and">></p>
            <p class="color9 fontSize12">选课中心</p>
            <p class="color9 fontSize12 and">></p>
            <p class="color9 fontSize12">{{corDetailsCourse.industry_Title}}</p>
            <p class="color9 fontSize12 and">></p>
            <p class="color fontSize12">{{corDetailsCourse.course_Title}}</p>
          </div>
        </div>
        <!-- 课程介绍 -->
        <div class="curriculumTxt curriculumIndex" id="0">
          <p class="curriculum_title">课程介绍</p>
          <div class="curriculumTxt_content content">
            <p>{{corDetailsCourse.course_Introduce}}</p>

          </div>
        </div>
        <!-- 课程优势 -->
        <div class="advantage diyImg d-flex d-flex-middle d-flex-center">
          <img src="../assets/images/c_advantage_img.png" />
        </div>
        <!-- 讲师介绍 -->
        <div class="introduce curriculumIndex" id="1">
          <p class="curriculum_title">讲师介绍</p>
          <div class="introduceContent diyImg content d-flex d-flex-middle d-flex-between">
            <div class="ic_L">
              <div class="ic_L_img">
                <div class="diyImg" :style="{'background-image': 'url('+$store.state.httpUrl + corDetailsCourse.teacher_HeadImgPath+')'}"></div>
              </div>
              <router-link :to="'/TeacherDetail/' + corDetailsCourse.teacher_Id" class="ic_L_name">{{corDetailsCourse.teacher_RealName}}</router-link>
              <p class="ic_L_label">{{corDetailsCourse.industry_Title}}</p>
            </div>
            <div class="ic_R d-flex d-flex-middle d-flex-center">
              <p class="fontSize14 colorF">{{corDetailsCourse.teacher_Experience}}</p>
            </div>
          </div>
        </div>
        <!-- 课程目录 -->
        <div class="catalogue curriculumIndex" id="2">
          <p class="curriculum_title">课程目录</p>
          <div class="catalogueList content">
            <!-- <div class="catalogueLi">
              <div class="cLi_title on d-flex d-flex-middle d-flex-between">
                <div class="cLi_titleTxt d-flex d-flex-middle">
                  <p>试听章节</p>
                  <span>免费</span>
                </div>
                <div v-if="!showVideoList1"   class="cLi_titleBtn d-flex d-flex-middle" @click="showVideoList1=!showVideoList1">
                  <p class="color3 fontSize12">展开更多</p>
                  <img src="../assets/images/down_icon_3.png" />
                </div>
                <div v-else class="cLi_titleBtn d-flex d-flex-middle" @click="showVideoList1=!showVideoList1">
                  <p class="color3 fontSize12">收起全部</p>
                  <img src="../assets/images/top_icon_1.png" />
                </div>
              </div>
              <div class="cLi_list" v-if="showVideoList1" >
                <div class="cLi_li d-flex d-flex-middle d-flex-between">
                  <p class="fontSize14 color3">前言</p>
                  <span href="javascript:;" data-src="https://static.szhoppo.com/video/haobo_v2.mp4" @click="showVideoMadal('https://static.szhoppo.com/video/haobo_v2.mp4')">免费试听</span>
                </div>
              </div>
            </div> -->
            <div class="catalogueLi">
              <div class="cLi_title d-flex d-flex-middle d-flex-between">
                <div class="cLi_titleTxt d-flex d-flex-middle">
                  <p>课程目录</p>
                </div>
                <!-- <div v-if="!showVideoList2" class="cLi_titleBtn d-flex d-flex-middle" @click="showVideoList2=!showVideoList2">
                  <p class="color3 fontSize12">展开更多</p>
                  <img src="../assets/images/down_icon_3.png" />
                </div>
                <div v-else class="cLi_titleBtn d-flex d-flex-middle" @click="showVideoList2=!showVideoList2">
                  <p class="color3 fontSize12">收起全部</p>
                  <img src="../assets/images/top_icon_1.png" />
                </div> -->
              </div>
              <div class="cLi_list">
                <div class="cLi_li d-flex d-flex-middle d-flex-between" v-for="(item,index) in listVideo" :key="index">
                  <p class="fontSize14 color3">{{item.video_Title}}</p>
                  <span href="javascript:;" data-src="https://www.w3school.com.cn/i/movie.ogg" @click="openPlay(item)">免费试听</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 常见问题 -->
        <div class="problem curriculumIndex" id="3">
          <p class="curriculum_title">常见问题</p>
          <div class="problemList">
            <div class="problemLi" v-for="(item,index) in questionList" :key="index">
              <p>Q：{{item.question_Title}}</p>
              <p class="color9 fontSize16">A：{{item.question_Content}}</p>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div id="bottomDiv"></div>
      <!-- 视频弹窗 -->
      <div class="videoAlert" v-if="videoAlertShow">
        <div class="vContent">
          <div class="vc_top d-flex d-flex-middle d-flex-between">
            <p> {{videoItem.video_Title}}</p>
            <img src="../assets/images/close.png" class="close" @click="closeVideoMadal" />
          </div>
          <div class="videoBox">
            <!-- <video controls="controls" id="myVideo">
              <source :src="videoUrl">
            </video> -->
            <div class="qcode-moadal">
              <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true" :options="playerOptions" @timeupdate="onPlayerTimeupdate($event)"></video-player>
              <div class="qc-cnt" v-if="qrcodShow">
                <div class="inner">
                  <img src="../assets/images/qrcod.png" alt="">
                  <div class="txt">扫码进入微信小程序</div>
                  <div class="txt">领取更多专属课程</div>
                </div>
              </div>
            </div>
          </div>
          <div class="vc_bottom d-flex d-flex-middle d-flex-between">
            <div class="vc_b_txt d-flex d-flex-middle">
              <p class="color9 fontSize14">当前播放：</p>
              <p class="color3 fontSize14">{{videoItem.video_Title}} </p>
              <!-- <span>免费</span> -->
            </div>
            <div class="vc_btns d-flex d-flex-middle">
              <p @click="changePrev(videoItem)">上一讲</p>
              <p @click="changeNext(videoItem)">下一讲</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Foot />
    <RightGuide />
  </div>
</template>

<script>
import Menu from "@/components/Menu";
import Foot from "@/components/Foot";
import RightGuide from "@/components/RightGuide";
import { courseById, watchCourse, allQuestion } from "@/api/app"
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'
import 'videojs-contrib-hls'


export default {
  components: {
    Menu, Foot, RightGuide, videoPlayer
  },
  data() {
    return {
      showVideoList2: false,
      listVideo: [],
      corDetailsCourse: {},
      videoAlertShow: false,
      qrcodShow: false,
      courseId: "",

      videoItem: {},


      playsinline: true,
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: 'application/x-mpegURL', // 类型
          src: '' // url地址
        }],
        poster: '', // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true // 是否显示全屏按钮
        }
      },
      gklog: '',    //观看时长
      questionList:[], //问题列表
    }
  },

  mounted() {
    this.courseId = this.$route.params.id;
    this.getCourseById()
    this.getWatchCourse()
    this.getAllQuestion()
  },
  methods: {

    /* 获取视频播放进度 */
    onPlayerTimeupdate(player) {
      this.gklog = player.cache_.currentTime
      //  console.log(' onPlayerTimeupdate!', this.gklog)

      //超出观看时长
      if (this.gklog > this.videoItem.video_Secound) {
         this.$refs.videoPlayer.player.pause()
        //  this.playerOptions['sources'][0]['src'] = 'https://video-convert.oss-cn-hangzhou.aliyuncs.com/videom3u8/76GdQhpFbN/76GdQhpFbN.m3u8'
        this.qrcodShow = true
        // this.$refs.videoPlayer.player.src('https://video-convert.oss-cn-hangzhou.aliyuncs.com/videom3u8/76GdQhpFbN/76GdQhpFbN.m3u8')
      }
    },



    closeQrcod() {

    },

    // 打开视频
    openPlay(item) {
      console.log(item)
      this.videoItem = item
      this.videoAlertShow = true
      this.playerOptions.sources[0].src = this.$store.state.videoUrl + item.video_Path
    },

    closeVideoMadal() {
      this.videoAlertShow = false
      //关闭二维码
      this.qrcodShow = false
    },


    //上一个
    changePrev(item) {
      console.log(item)
      this.listVideo.forEach((el, index) => {
        if (el.video_Id == item.video_Id) {
          console.log(index)
          if (index == 0) {
            alert('没有上一个了')
            return
          }
          this.qrcodShow = false
          this.videoItem = this.listVideo[index - 1]
          this.playerOptions.sources[0].src = this.$store.state.videoUrl + this.listVideo[index - 1].video_Path
        }
      });
    },

    //下一个
    changeNext(item) {
      console.log(item)
      this.listVideo.forEach((el, index) => {
        if (el.video_Id == item.video_Id) {
          console.log(index)
          if (index == this.listVideo.length - 1) {
            alert('没有下一个了')
            return
          }
          this.qrcodShow = false
          this.videoItem = this.listVideo[index + 1]
          this.playerOptions.sources[0].src = this.$store.state.videoUrl + this.listVideo[index + 1].video_Path
        }
      });
    },


    //课程目录列表
    getWatchCourse() {
      const params = {
        course_Id: this.courseId,
      }
      watchCourse(params).then((res) => {
        if (res.status) {
          const data = res.data
          this.listVideo = data.listVideo
        }
      })
    },

    //课程详情
    getCourseById() {
      const params = {
        course_Id: this.courseId,
        uid: ''
      }
      courseById(params).then((res) => {
        if (res.status) {
          const data = res.data
          this.corDetailsCourse = data.course
        }
      })
    },

    //常见问题
    getAllQuestion() {
      allQuestion().then((res) => {
        if (res.status) {
          const data = res.data
          this.questionList = data
        }
      })
    },
  }
}
</script>

<style lang="scss">
body {
  background-color: #fff;
}
#curricuLum {
  /*  */

  /*  */
  .curriculumTop {
    height: 276px;
    line-height: 276px;
    text-align: center;
    background-image: url(../assets/images/curriculumBg.png);
  }
  .curriculumTop p {
    font-size: 36px;
    color: #fff;
  }
  .curriculumInfo {
    width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 22;
    top: -40px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 30px 60px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
  }
  .curriculum_info_L_top {
    margin-bottom: 10px;
  }
  .price {
    color: #ea2c18;
    font-size: 30px;
    font-weight: bold;
  }
  .curriculum_info_L_top .color3 {
    margin-left: 20px;
  }
  .curriculum_info_L_top p span {
    color: #0091ff;
    font-size: 12px;
  }
  .curriculum_info_L_bottom p {
    line-height: 14px;
    padding-right: 20px;
    margin-right: 20px;
    border-right: 1px solid #333;
    font-size: 12px;
  }
  .curriculum_info_L_bottom p:nth-last-of-type(1) {
    border-right: none;
  }
  .curriculum_info_R {
  }
  .join {
    width: 120px;
    height: 40px;
    line-height: 40px;
    color: #fff;
    background: #0091ff;
    border-radius: 20px;
    text-align: center;
    cursor: pointer;
  }
  .collectionBox {
    width: 120px;
    height: 40px;
    line-height: 40px;
    border: 1px solid #0091ff;
    border-radius: 20px;
    cursor: pointer;
    margin-left: 24px;
  }
  .collectionBox img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  /*  */
  .tabFixed {
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99999;
    display: none;
  }
  .tabs {
    height: 90px;
  }
  .tabs p {
    min-width: 90px;
    margin: 0 20px;
    cursor: pointer;
    font-size: 14px;
    position: relative;
    text-align: center;
    line-height: 80px;
  }
  .tabs .active:after {
    background-color: #0091ff;
  }
  .tabs p::after {
    content: "";
    position: absolute;
    bottom: 15px;
    width: 20px;
    height: 4px;
    left: 50%;
    margin-left: -10px;
    background-color: #fff;
  }
  .tabs p:hover:after {
    background-color: #0091ff;
  }
  .tabBtns div {
    margin-right: 24px;
  }
  .tabBtns div p:nth-of-type(1) {
    color: #ea2c18;
    margin-bottom: 5px;
    margin-right: 5px;
  }
  /*  */
  .curriculum_title {
    height: 96px;
    background: url(../assets/images/section_icon.png) center no-repeat;
    background-size: contain;
    line-height: 74px;
    font-size: 30px;
    color: #fff;
    font-weight: bold;
    text-align: center;
  }
  /*  */
  .curriculumTxt {
    margin-top: 50px;
  }
  .curriculumTxt_content {
    line-height: 24px;
    text-indent: 2em;
    margin-top: 50px;
  }
  .advantage {
    height: 690px;
    background-image: url(../assets/images/c_advantage.png);
    margin: 80px 0;
  }
  .introduceContent {
    background-image: url(../assets/images/c_t_bg.png);
    height: 240px;
    box-sizing: border-box;
    padding: 36px 72px;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 60px;
  }
  .ic_L {
    width: 120px;
    margin-right: 100px;
    text-align: center;
  }
  .ic_L_img {
    width: 90px;
    height: 90px;
    border-radius: 45px;
    border: 2px solid #8ac2f8;
    box-sizing: border-box;
    padding: 5px;
    margin: 0 auto;
  }
  .ic_L_img div {
    border-radius: 40px;
    width: 100%;
    height: 100%;
  }
  .ic_L_name {
    line-height: 50px;
    font-size: 20px;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
  }
  .ic_L_label {
    width: 120px;
    box-sizing: border-box;
    min-height: 30px;
    background: #ffffff;
    border-radius: 15px;
    line-height: 20px;
    padding: 5px;
    color: #0087ed;
    font-size: 12px;
    margin: 0 auto;
  }
  .ic_R {
    flex: 1;
    line-height: 24px;
  }
  /*  */
  .catalogue {
    margin: 100px 0;
  }
  .catalogueList {
    margin-top: 30px;
    border: 1px solid #eee;
    border-bottom: none;
  }
  .catalogueLi {
    border-bottom: 1px solid #eee;
    box-sizing: border-box;
    padding: 10px 40px 10px 40px;
  }
  .cLi_title {
    cursor: pointer;
    height: 40px;
  }
  .cLi_titleTxt p {
    color: #333;
    font-size: 16px;
  }
  .cLi_titleTxt span {
    width: 40px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    color: #fff;
    font-size: 12px;
    background-color: #0091ff;
    border-radius: 5px;
    margin-left: 10px;
  }
  .cLi_titleBtn img {
    width: 12px;
    height: 8px;
    margin-left: 8px;
  }
  .cLi_title .cLi_titleBtn:nth-of-type(3) {
    // display: none;
  }
  .cLi_list {
    // display: none;
  }
  .cLi_li {
    height: 44px;
    background-color: #fbfbfb;
    box-sizing: border-box;
    padding: 0 30px;
    margin: 10px 0;
  }
  .cLi_li span {
    width: 90px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #0091ff;
    font-size: 14px;
    background: #ffffff;
    border: 1px solid #0091ff;
    border-radius: 15px;
    cursor: pointer;
  }
  /*  */
  .on .cLi_titleBtn:nth-of-type(2) {
    // display: none;
  }
  .on .cLi_titleBtn:nth-of-type(3) {
    display: flex;
  }
  .on + .cLi_list {
    display: block;
  }
  /*  */
  .problem {
    padding-bottom: 100px;
  }
  .problemList {
    width: 940px;
    margin: 45px auto 0 auto;
  }
  .problemLi {
    min-height: 120px;
    border-bottom: 1px solid #eee;
    box-sizing: border-box;
    padding: 30px 0 20px 0;
  }
  .problemLi p:nth-of-type(2) {
    line-height: 20px;
    margin: 10px 0;
  }
  .problemLi p:nth-of-type(1) {
    line-height: 20px;
    margin-bottom: 20px;
    font-size: 18px;
    color: #333;
    font-weight: bold;
    box-sizing: border-box;
    padding-left: 10px;
    border-left: 4px solid #0091ff;
  }
  /*  */
  .videoAlert {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999999;
    left: 0;
    top: 0;
    // display: none;
  }
  .vContent {
    width: 1024px;
    height: 720px;
    background: #ffffff;
    border-radius: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .vc_top {
    height: 96px;
    box-sizing: border-box;
    padding: 0 30px;
    border-bottom: 1px solid #eee;
  }
  .vc_top p {
    color: #333;
    font-size: 24px;
    font-weight: bold;
  }
  .vc_top img {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  .vContent .videoBox {
    box-sizing: border-box;
    padding: 30px 60px 0 60px;
    height: 538px;
  }
  .vContent .videoBox video {
    width: 100%;
    height: 100%;
  }
  .vc_bottom {
    box-sizing: border-box;
    padding: 0 30px;
    height: 74px;
  }
  .vc_bottom .vc_b_txt span {
    width: 40px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    background-color: #0091ff;
    color: #fff;
    border-radius: 5px;
  }
  .vc_bottom .vc_btns p {
    margin-left: 20px;
    width: 90px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    background: #0091ff;
    border-radius: 5px;
  }

  .vjs-progress-control {
    pointer-events: none !important;
  }
  .video-js .vjs-big-play-button {
    top: 50%;
    left: 50%;
    margin: -40px;
  }

  .qcode-moadal {
    position: relative;
    .qc-cnt {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.3);

      .inner {
        width: 200px;
        margin: 90px auto 0;
        text-align: center;
        background: #fff;
        border-radius: 30px;
        padding: 40px 80px;
        position: relative;
        .txt {
          line-height: 20px;
        }
      }
    }
  }
}
</style>